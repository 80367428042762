  /****************************************
  * MAIN CONTAINER STYLES
  ****************************************/
  .comments-main-container {
    max-width: 100%;
    height: calc(82vh - var(--header-height));
    display: flex;
    /* background: #fff; */
    background-color: red;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 2px 5px rgba(0, 0, 0, 0.6);
    overflow-y: auto;

  }

  /****************************************
  * LEFT CONTAINER (FIXED WIDTH)
  ****************************************/
  .comments-left-container {
    width: 350px; /* Fixed width */
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
  }

  .comments-search-container {
    display: flex;
    align-items: center;
    background: #f6f6f6;
    height: 40px;
    padding: 10px;
  }

  .comments-search-input {
    width: 100%;
    padding: 5px 10px;
    border: none;
    border-radius: 10px;
    background: #fff;
    font-size: 12px;
  }

  .comments-chat-list {
    flex: 1;
    overflow-y: auto;
  }

  .comments-chat-box {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
  }

  .comments-chat-box.selected {
    border: 2px solid green;
    background-color: rgba(17, 43, 26, 0.1);
    cursor: pointer;
  }

  .comments-chat-box {
    background-color: transparent;
    border: 2px solid transparent;   
  }

  .role-description {
    font-style: italic;
    font-size: 0.9em;
    color: grey;
    margin-right: 15px;
  }

  .comments-chat-box .unread-count {
    background-color: var(--mmg-yellow);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 0.75em;
    font-weight: bold;
    margin-right: 10px;
  }

  .comments-chat-box .role {
    font-weight: 600;
    margin-right: auto;
  }

  .comments-chat-box .time {
    font-size: 0.75em;
    color: #aaa;
  }

  .unread-count.hidden {
    visibility: hidden;
  }

/****************************************
 * CHATBOX INPUT
 ****************************************/
.chatbox-input {
  width: 75%; /* Adjusted width */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1em;
  margin-right: 10px; /* Spacing between input and button */
}

.chatbox-send-button {
  padding: 10px 20px;
  background-color: var(--mmg-button-blue-colour); /* Use existing blue button color */
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.chatbox-send-button:hover {
  background-color: var(--mmg-button-blue-hover-colour); /* Use existing hover color */
}



  /****************************************
  * RIGHT CONTAINER (FLEXIBLE)
  ****************************************/
  .comments-right-container {
    position: relative; /* Ensures child elements position themselves relative to this container */

    flex: 1; /* Flexible width */
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #e5ddd5;
    /* background: yellow; */
  }

  .comments-chat-container {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between messages */
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    margin-bottom: 40px;
  }

  /****************************************
  * MESSAGE BOXES
  ****************************************/
  .comments-message-box {
    display: flex;
    margin: 5px 0;
  }

  .comments-message-box p {
    background: rgba(255, 177, 31, 0.4);
    padding: 12px;
    border-radius: 10px;
    font-size: 1.1rem;
    line-height: 1.5;
  }

  .comments-my-message p {
    position: relative;
    background: #eace9c;
    border-radius: 10px;
    margin-bottom: 10px;
    text-align: right;
  }

  .comments-my-message p::before {
    content: '';
    position: absolute;
    top: 0;
    right: -12px;
    width: 20px;
    height: 20px;
    background: linear-gradient(135deg, #eace9c 50%, transparent 50%);
  }

  .comments-friend-message p {
    position: relative;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .comments-friend-message p::before {
    content: '';
    position: absolute;
    top: 0;
    left: -12px;
    width: 20px;
    height: 20px;
    background: linear-gradient(225deg, #fff 50%, transparent 50%);
  }

  .comments-message-box.comments-my-message {
    justify-content: flex-end;
    text-align: right; 
  }

  /****************************************
  * INPUT BOTTOM BAR
  ****************************************/
  .comments-chatbox-input {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; /* Ensures it spans the full width of the container */
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;  }

  .comments-chatbox-input input {
    width: 85%;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 1em;
    border: none;
    margin-left: 20px;
  }

  /****************************************
  * MESSAGE NAMES IN BUBBLE
  ****************************************/
  .sender-name {
    font-weight: bold;
  }

  .recipient-name {
    font-style: italic;
    color: #666; /* Lighter color for distinction */
    margin-left: 5px;
  }


  /****************************************
  * INFO PANEL AT BOTTOM OF CHAT LIST
  ****************************************/
  .info-panel {
    padding: 10px;
    font-size: 0.9em;
    color: #555;
    text-align: center;
    border-top: 1px solid #eee;
    background: #f9f9f9;
  }

  /****************************************
  * SENDING TO TEXT IN INPUT AREA
  ****************************************/
  .sending-to {
    font-size: 0.9em;
    color: #555;
    margin-bottom: 5px; /* Adjust spacing if placed above */
    margin-left: 10px;
  }

  .sending-to span {
    font-weight: bold;
    color: #333;
  }

  /****************************************
  * REPLY CHEVRON PLACEHOLDER
  ****************************************/
  .chevron {
    font-size: 0.8em;
    color: #999;
    cursor: pointer;
    margin-left: 8px;
  }

  /****************************************
  * TIMESTAMP IN MESSAGE BUBBLES
  ****************************************/
  .timestamp {
    font-size: 0.75em; /* Smaller font size */
    color: #3b3a3a;
    float: right; /* Align to the right within the bubble */
    margin-top: 10px; /* Adjust spacing as needed */
  }

