/* CompleteProfileAgent.module.css START */

/****************************************
 * Form Container
 ****************************************/
 .formContainer {
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/****************************************
 * Branding Header
 ****************************************/
 .brandingHeaderPanel {
  display: flex;
  align-items: center; /* Align logo and text vertically */
  justify-content: center; /* Center content */
  gap: 15px; /* Space between logo and text */
  text-align: center;
  font-family: 'Libre Baskerville', serif;
  font-size: 2.3rem;
  font-weight: 600;
  color: #D7CE96;
  background-color: #112B1A;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
}

.logo {
  height: 50px; /* Adjust size as needed */
  width: auto;
}

/****************************************
 * Fieldsets and Legends
 ****************************************/
.fieldset {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff;
}

.legend {
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background-color: #112B1A;
  padding: 5px 10px;
  border-radius: 8px;
}

/****************************************
 * Rows and Inputs
 ****************************************/
.row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 15px;
}

.row.threeColumns > div {
  flex: 0 1 calc(33.33% - 20px); /* 3 columns */
}

.row.fourColumns > div {
  flex: 0 1 calc(25% - 20px); /* 4 columns */
}

.label {
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
  color: #555;
}

.input {
  width: 100%;
  padding: 8px 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.input:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

/****************************************
 * Terms and Conditions
 ****************************************/
.termsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
}

/****************************************
 * Submit Button
 ****************************************/
.submitContainer {
  text-align: center;
}

.submitButton {
  width: 50%;
  padding: 10px;
  font-size: 1.1rem;
  color: white;
  background-color: #112B1A;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #08140c;
}

/* CompleteProfileAgent.module.css END */
