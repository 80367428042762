/* [LandlordDashboard.css START] */
 
/****************************************
 * LANDLORD DASHBOARD STYLES
 ****************************************/

 /* .landlord-dashboard {
  margin-top: var(--header-height);
  padding: 20px;
  height: calc(95vh - var(--header-height));
  background-color: purple;
} */

.dashboard-header h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

/****************************************
 * LANDLORD CONTACT INFO STYLES
 ****************************************/

.landlord-contact {
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 1.1em;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f3f4f6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.landlord-contact p {
  margin: 0;
  font-weight: 500;
  color: #333;
}

.edit-button {
  margin-left: auto;
  padding: 6px 8px;
  background-color: #d3d3d3;
  color: var(--mmg-txtColor-contrast);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.edit-button:hover {
  background-color: #b0b0b0;
}

/****************************************
 * LANDLORD PROPERTY PANEL STYLES
 ****************************************/

.landlord-property-panel {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.property-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.property-details p {
  margin: 0;
  font-size: 1em;
  color: #555;
}

.quote-button {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.9em;
  transition: background-color 0.3s;
  width: 33vw;
  height: 200px;
}

.quote-button:hover {
  background-color: #0056b3;
}

/****************************************
 * GRID SECTION STYLES
 ****************************************/

.manage-properties-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.grid-section {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.property-count {
  font-size: 0.8em;
  color: #555;
  text-align: center;
  margin-top: 10px;
}

/****************************************
 * REQUEST A QUOTE PANEL
 ****************************************/

.request-quote-panel {
  /* background-color: red; */
  align-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100px;
  color: var(--mmg-txtColor-contrast);
  padding: 10px;
  margin-top: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  justify-content: center;
  align-items: center; 
}

.request-quote-panel-description {
  background-color: var(--mmg-grid-title-background-colour);
  border: 0.5px solid lightgray;
  margin-left: 10px;
  padding: 5px;
  border-radius: 8px;
  /* font-size: 21px; */
  font-weight: bold;
}

.pre-quoted-works-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;    
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 170px;

}

.pre-quoted-work-button:hover {
  background-color: #0056b3;
  cursor: pointer;
}

.buttons-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.pre-quoted-work-button {
  border: none;
  padding: 1rem;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  text-align: left;
  height: fit-content;
  margin-bottom: 20px;
  margin-top: 20px;
}

.pre-quoted-work-title {
  font-weight: bold;
}

.pre-quoted-work-details {
  font-size: 0.875rem;
}

/****************************************
 * JobTabs Styles
 ****************************************/
 .job-tabs-containerx {
  padding: 20px;
  /* background-color: #fff; */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.jobs-header {
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/****************************************
 * Main Content / Jobs toggle button
 ****************************************/

.toggle-main-content-btn {
  border: none;
  padding: 1rem;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  text-align: left;
  height: fit-content;
}

.toggle-main-content-btn:hover {
  background-color: #0056b3;
  cursor: pointer;  
}

/* [LandlordDashboard.css END] */
