/* [JobRequestFormJobDetails.css START] */

/****************************************
 * Layout Structure
 ****************************************/
 .job-request-form {
  padding: 20px;
}

.trades-selection-panel {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  margin-bottom: 20px;
}

.trade-button {
  padding: 10px;
  background-color: #f0f0f0;
  color: var(--mmg-txtColor-contrast);
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.trade-button:hover {
 background-color: #112B1A; 
 color: #fff;;
}

.trade-button.selected {
  background-color: #007bff;
  color: white;
}

.selected-trades-panel {
  padding: 10px;
  border-top: 1px solid #ddd;
}

.selected-trades-panel h3 {
  margin-top: 0;
}

/****************************************
 * Trade Detail Row
 ****************************************/
.trade-detail-row {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  gap: 10px;
  margin-bottom: 15px;
  background-color: var(--mmg-grid-title-background-colour);
  padding: 5px;
  margin-top: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.trade-detail-row h4 {
  grid-column: 1 / span 1;
  font-size: 1.1em;
  margin-bottom: 5px;
}

.trade-detail-row textarea {
  grid-column: span 1;
  padding: 8px;
  font-size: 1em;
  resize: vertical;
}

/****************************************
 * Styling for Input Box under H4
 ****************************************/
.other-trade-input {
  grid-column: 1 / span 1; /* Only span the first column */
  margin-top: 10px; /* Adds space between the h4 and the input box */
  width: 100%; /* Ensure the input takes the full width */
}

textarea,
input[type="text"] {
  width: 100%; /* Ensure the textarea and input fill the container's width */
  padding: 8px; /* Add padding for better usability */
  margin-top: 10px; /* Space between elements */
  box-sizing: border-box; /* Ensure padding doesn't overflow */
}

/****************************************
 * Trade Photo Row (Second Row)
 ****************************************/
 .trade-photo-row {
  grid-column: 1 / -1; /* Span all columns of the parent grid */
  display: grid;
  grid-template-columns: 1fr; /* Single-column layout */
  gap: 10px;
  margin-top: 10px;
  align-items: center;
}

.add-photo-button {
  flex: 0 0 auto; /* Prevent it from stretching */
  width: 150px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-photo-button:hover {
  background-color: #0056b3;
}

.uploaded-photos {
  /* grid-column: 1 / -1; Span the entire row */
  flex: 1 1 100%; /* Ensure it spans the full width below the button */
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.uploaded-photo {
  width: 100px;
  /* max-width: 400px; */
  height: auto;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 5px;
}


/* [JobRequestFormJobDetails.css END] */
