/****************************************
 * Settings Landing Page Styles
 ****************************************/

 .settings-landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px;
}

.settings-landing h2 {
  font-size: 2em;
  color: #333;
  margin-bottom: 10px;
}

.settings-landing p {
  font-size: 1.2em;
  color: #666;
  max-width: 600px;
  line-height: 1.6;
}
