/****************************************
 * Appointment Form Styles
 ****************************************/

 .appointment-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.appointment-form label {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #333;
}

.appointment-form input[type="date"],
.appointment-form input[type="time"],
.appointment-form textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1em;
}

.appointment-form textarea {
  resize: vertical;
  height: 80px;
}

.appointment-form button {
  align-self: flex-start;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.appointment-form button:hover {
  background-color: #0056b3;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
