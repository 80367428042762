/* QuoteForm.module.css START */

/****************************************
 * Main Quote Form Styles
 ****************************************/
 .quoteForm {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

/****************************************
 * Three-Column Row for Trade, Job Description, and Further Information
 ****************************************/
.threeColumnRow {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.formGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.formGroup p {
  background: #f9f9f9;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/****************************************
 * Submit Button and Message
 ****************************************/
.submitBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.submitMessage {
  margin-top: 10px;
  color: green;
}

.submitBtn button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  background-color: #4CAF50; /* Green color for the submit button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/****************************************
 * General Form Styling
 ****************************************/
.formRow {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.quoteFormTitle {
  font-weight: bold;
}



/****************************************
 * Job Images Styling
 ****************************************/

.imageSection {
  margin-top: 2px;
}

.imageGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.imageContainer {
  width: 80px; /* Adjust thumbnail size as needed */
  height: 80px;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: transform 0.2s ease;
  margin-bottom: 30px;
}

.imageContainer:hover {
  transform: scale(1.05);
}

.jobImage {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fits without distortion */
}


/* QuoteForm.module.css END */
