/****************************************
 * AdministrationContent.css START
 ****************************************/

 .page-content.collapsed {
  left: 60px; /* Match the collapsed width of the side panel */
  width: calc(100% - 60px);
}
/****************************************
 * AdministrationContent.css END
 ****************************************/
