/****************************************
 * App.css START
 ****************************************/

@import './Variables.css';
@import url("https://fonts.googleapis.com/css?family=Lato:400,700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a, button {
  color: inherit;
}

a {
  text-decoration: none;
}

button {
  /* background-color: var(--mmg-background-colour-hover); */
  color: var(--mmg-txtColor);
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--mmg-background-colour-hover);
}

body {
  font: 16px/1.5 "Lato", sans-serif;
  background-color: var(--mmg-background-colour);
  color: var(--mmg-txtColor);
}


/* Global dark mode styles */
.dark-mode {
  --background-color: #121212;
  --text-color: #ffffff;
  --button-color: #1f1f1f;
}

/* Global light mode styles */
.light-mode {
  --background-color: #ffffff;
  --text-color: #000000;
  --button-color: #f0f0f0;
}

/****************************************
 * NAVIGATION STYLES
 ****************************************/
#nav {
  position: fixed;
}

/****************************************
 * PAGE CONTENT STYLES
 ****************************************/
.page-content {
  position: relative;
  left: var(--sidenav-width);
  width: calc(100% - var(--sidenav-width));
  padding: 10px;
  color: var(--mmg-txtColor);
  background-color: var(--mmg-background-colour);
  /* background-color: var(--cmh-pale-panel-background); */
  top: var(--header-height);
  z-index: 2;
  height: calc(100vh - var(--header-height));
  box-shadow: var(--box-shadow);
  overflow: auto;
}

.page-content .content-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

.page-content .content-grid > article {
  display: flex;
  height: 300px;
  background: var(--page-content-blockColor);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.page-content .content-grid > article:first-child,
.page-content .content-grid > article:last-child {
  grid-column: 1 / -1;
}

/****************************************
 * THEME SWITCH STYLES
 ****************************************/
.theme-switch label {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.theme-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.theme-switch label {
  background-color: red;
  border-radius: 15px;
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.theme-switch label span {
  display: inline-block;
  background-color: green;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: 0.3s;
}

input:checked + label {
  background-color: var(--theme-switch-bgLightModeColor);
}

input:checked + label span {
  background-color: var(--theme-switch-sunColor);
  transform: translateX(30px);
}


.positive-button {
  background-color: var(--mmg-button-green-colour);
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.positive-button:hover {
  background-color: var(--mmg-button-green-hover-colour);
}

.negative-button {
  background-color: var(--mmg-button-blue-colour);
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.negative-button:hover {
  background-color: var(--mmg-button-blue-hover-colour);
}


/****************************************
 * App.css END
 ****************************************/



 .dashboard-main-content-container {
  /* position: fixed;  */
  top: var(--header-height); 
  left: 0;
  right: 0;
  bottom: 0; 
  padding: 0 20px;
  overflow-y: auto; 
  overflow-x: hidden; 
  background-color: #fff;
  color: black;
}

/****************************************
 * Job Tabs Container
 ****************************************/
 .job-tabs-container {
  display: flex;
  flex-direction: column;
  height: calc(95vh - var(--header-height));
  padding: 0;
  overflow: hidden; /* Prevent scrolling on the container */
  background-color: #fff;
  color: black;
}
