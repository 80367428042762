/****************************************
 * FlashMessage.css START
 ****************************************/

 /****************************************
 * FLEX GROUP STYLES
 ****************************************/

 .flex-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  color: var(--mmg-txtColor-contrast);
}

.flash-message {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.flash-message.success {
  color: #4caf50; /* Green for success */
}

.flash-message.error {
  color: #f44336; /* Red for error */
}

.flash-message.fade-out {
  opacity: 0;
}

/****************************************
 * FlashMessage.css END
 ****************************************/
