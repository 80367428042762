/****************************************
 * DataVisibilitySettings.css START
 ****************************************/

 
.data-visibility-settings {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* max-width: 800px; */
  margin: 0 auto;
}

.dropdown-container {
  margin-bottom: 20px;
}

.dropdown-container select {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.visibility-settings {
  display: grid;
  grid-template-columns: 2fr repeat(3, 1fr); /* Expand first column for readability */
  gap: 10px;
  align-items: center;
  border-top: 1px solid #ddd;
  padding-top: 20px;
  color: var(--mmg-txtColor-contrast);
}

.header-row, .column-setting {
  display: contents;
}

.header-row span,
.column-setting span,
.role-header,
.role-checkbox {
  padding: 5px 0;
}

.column-setting span:first-child {
  text-align: left; /* Align first column to the left */
  font-weight: 500;
}

/* Row shading */
.column-setting:nth-of-type(odd) {
  background-color: #f3f4f6;
}
.column-setting:nth-of-type(even) {
  background-color: #ffffff;
}

.role-checkbox input {
  margin: 0 auto;
}

/****************************************
 * DataVisibilitySettings.css END
 ****************************************/
