/* [PreQuotedWorkForm.css START] */

/****************************************
 * Form Layout
 ****************************************/
 .pre-quoted-work-form {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pre-quoted-work-form h3 {
  margin-bottom: 10px;
}

.pre-quoted-work-form p {
  margin: 8px 0;
}

.pre-quoted-work-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

/****************************************
 * Buttons
 ****************************************/
.confirm-btn {
  background-color: var(--mmg-button-green-colour); /* Green for confirm */
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

.cancel-btn {
  background-color: var(--mmg-button-blue-colour); /* Blue for cancel */
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

/****************************************
 * Hover and Focus States
 ****************************************/
.confirm-btn:hover,
.confirm-btn:focus {
  background-color: var(--mmg-button-green-hover-colour);
}

.cancel-btn:hover,
.cancel-btn:focus {
  background-color: var(--mmg-button-blue-hover-colour);
}

/* [PreQuotedWorkForm.css END] */
