/* [JobTabs.css START] */


/****************************************
 * Property Details Panel
 ****************************************/
.property-details-panel {
  background-color: rgba(17, 43, 26, 0.1); /* Light green tint */
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(17, 43, 26, 0.2);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.property-details-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem; /* Add spacing below the header row */
}

.property-details-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--text-primary);
  margin: 0;
}

.property-details-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 equal columns */
  gap: 1rem;
}

.property-details-item {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.property-details-title {
  display: block;
  font-weight: bold;
  background-color: rgba(17, 43, 26, 0.8); /* Dark green */
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
  text-align: center;
  white-space: nowrap;
  width: 120px; /* Uniform width for all titles */
  flex-shrink: 0; /* Prevent shrinking */
}

.property-details-value {
  color: var(--text-secondary);
  font-size: 1rem;
  flex: 1; /* Allow values to grow dynamically */
  word-wrap: break-word;
}

/****************************************
 * Button Group and Tabs
 ****************************************/
 .job-tabs-button-group {
  display: flex;
  justify-content: space-between; /* Align left and right sections */
  padding: 0.5rem 1rem;
  gap: 1rem;
  background-color: var(--background-light);
  border-bottom: 2px solid var(--border-color);
}

.tabs-left {
  display: flex;
  gap: 1rem;
  width: fit-content;
}

.buttons-right {
  display: flex;
  gap: 1rem;
}

.job-tabs-button {
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  border-radius: 4px;
  transition: color 0.3s ease, border-bottom-color 0.3s ease;
}

.job-tabs-button:hover {
  color: var(--text-primary);
}

.job-tabs-button-active {
  color: var(--accent-color);
  border-bottom-color: var(--accent-color);
}

/****************************************
 * Tab Content
 ****************************************/
.job-tabs-content {
  flex: 1;
  overflow-y: auto; /* Enable scrolling inside the content area */
  padding: 1rem;
  background-color: var(--content-bg-color);
}


/****************************************
  * Basic Modal Styles 
****************************************/

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  width: 60%;
  max-width: 600px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}


/* [JobTabs.css END] */
