/* [SettingsPlatinum.module.css START] */
/****************************************
 * Settings Platinum Styles
 ****************************************/

 .container {
  margin: 20px;
  font-family: Arial, sans-serif;
}

.label {
  display: block;
  margin: 10px 0 5px;
  font-weight: bold;
}

.input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.title {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.subtitle {
  margin-top: 20px;
  font-size: 1.2em;
  color: #333;
}

.text {
  margin: 5px 0;
  color: #555;
}

.makeBold {
  font-weight: bold;
  /* background-color: red; */
}
/* [SettingsPlatinum.module.css END] */
