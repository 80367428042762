/****************************************
 * Dashboard.css START
 ****************************************/

 .dashboard-container {
  display: grid;
  gap: 10px;
  width: 100%;
  height: fit-content;
}

.dashboard-container.two-columns {
  grid-template-columns: 1fr 1fr; /* Two equal-width columns */
  gap: 20px; /* Double the gap between the two columns */
}

.dashboard-container.single-column {
  grid-template-columns: 1fr; /* Full-width single column */
  gap: 20px; /* Reduce gap in single-column mode */
}

.grid-panel {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  position: relative;
  width: 100%; 
  transition: height 0.3s ease; /* Smooth height transition */
  overflow: hidden; /* Prevent content from spilling out during transition */
}

.grid-panel.collapsed {
  background-color: #f0f0f0; /* Lighter background when collapsed */
  border-left: 5px solid #ccc; /* Visual indicator for collapsed state */
}

.title-panel {
  display: flex; /* Use flexbox for alignment */
  justify-content: space-between; /* Space between title and button */
  align-items: center; /* Vertically center the button and title */
  background-color: var(--mmg-grid-title-background-colour);
  color: var(--mmg-grid-title-text-colour);
  padding: 5px 10px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  height: 35px;
}

.title-panel h4 {
  margin: 0;
  font-size: 16px;
  padding-left: 10px; /* Align title */
}

.collapse-btn {
  background-color: transparent; /* No background color */
  border: none; /* Remove button border */
  cursor: pointer;
  font-size: 20px;
  margin-right: 10px;
}

.collapse-btn i {
  margin-right: 0; /* Ensure icon aligns properly */
}

.ag-grid-container {
  flex-grow: 1;
  overflow: auto;
  width: 100%; /* Ensure AG Grid takes full width */
  height: 100%; /* Make sure it grows to fill the available space */
}

.menu-panel {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: #f9f9f9;
}

.menu-panel button {
  /* background-color: var(--mmg-background-colour); */
  color: var(--mmg-txtColor-contrast);
  border: none;
  padding: 10px; /* Uniform padding */
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center; /* Vertically center the icon */
  justify-content: center; /* Horizontally center the icon */
  width: 25px; /* Set width to ensure buttons are square */
  height: 25px; /* Set height to ensure uniform square buttons */
  margin: 0 5px; /* Add a small margin between buttons */
}

.menu-panel button:hover {
  background-color: var(--mmg-yellow);
}

.menu-panel i {
  margin-right: 0;
}

/****************************************
 * Dashboard.css END
 ****************************************/
