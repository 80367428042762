/* [ProfileForm.css START] */

.profile-form-container {
  height: 100%; /* Occupy all available vertical space */
  width: 100%; /* Occupy all available horizontal space */
  display: flex;
  flex-direction: column; /* Ensure child elements stack vertically */
  justify-content: space-between; /* Space out content if needed */
  padding: 20px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);}

.profile-form-row {
  display: flex;
  gap: 15px; /* Space between fields */
  margin-bottom: 20px;
}

.profile-form-row > div {
  flex: 1; /* Each field takes equal space */
  display: flex;
  flex-direction: column;
}

.profile-form-label {
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
  color: #333;
}

.profile-form-input {
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  width: 100%; /* Ensure the input spans the available space */
  box-sizing: border-box;
}

.profile-form-hidden {
  visibility: hidden; /* Keeps the space but hides the element visually */
}

.profile-form-hidden {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

/* [ProfileForm.css END] */
