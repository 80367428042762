.costBreakdownTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.costBreakdownTable th {
  background-color: #e0e0e0; /* Neutral grey */
  padding: 8px;
  text-align: left;
  border: 1px solid #ccc;
  font-weight: bold;
}

.costBreakdownTable td {
  padding: 8px;
  border: 1px solid #ccc;
  text-align: left;
}

.costBreakdownTable th:nth-child(3), /* Net £ */
.costBreakdownTable td:nth-child(3),
.costBreakdownTable th:nth-child(4), /* VAT £ */
.costBreakdownTable td:nth-child(4) {
  width: 8%;
}

.costBreakdownTable th:nth-child(2), /* Description */
.costBreakdownTable td:nth-child(2) {
  width: auto; /* Allow description to take more space */
}
