/****************************************
 * COLOR VARIABLES
 ****************************************/
 :root {
  /* Background Colors */
  --mmg-background-colour: #112B1A;
  --mmg-background-colour-hover: #1d2636;
  --mmg-yellow: #F1B445;
  
  /* Text Colors */
  --mmg-txtColor: #dde9f8;
  --mmg-txtColor-contrast: #000;

  /* Theme */
  --header-height: 110px;
  --sidenav-width: 220px;
  --job-top-panel-height: 200px;
  --mmg-grid-title-background-colour: #f5ecc7;
  --mmg-grid-title-text-colour: #000;

  --mmg-button-blue-colour: #007bff;
  --mmg-button-blue-hover-colour: #0056b3;
  
  --mmg-button-green-colour: #4CAF50;
  --mmg-button-green-hover-colour: #45a049;
  
  /* Shadows */
  --box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);

  --cmh-panel-background: rgba(17, 43, 26, 0.1);
  --cmh-bold-item-background: rgba(17, 43, 26, 0.8);
  --cmh-title-background-color: rgba(17, 43, 26, 0.8);
  --cmh-title-soft-background-color: rgba(17, 43, 26, 0.4);

  --cmh-pale-panel-background:#E7FFEC;
  /* --cmh-pale-panel-background:green; */

}

/****************************************
 * LIGHT MODE VARIABLES
 ****************************************/
 .light-mode {
  --mmg-background-colour: #f1efec;
  --mmg-background-colour-hover: #b9e4e0;
  --mmg-txtColor: #000;
  --headingColor: #979595;
  --box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.25);
}

/****************************************
 * FONT STYLES
 ****************************************/
body {
  font: 16px/1.5 "Lato", sans-serif;
}
