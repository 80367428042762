/****************************************
 * AgentManageProperties.css START
 ****************************************/

.manage-properties-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
}

.row {
  display: flex;
  width: 100%;
  gap: 10px;
}

.grid-section {
  flex: 1;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}

.full-width .grid-section {
  width: 100%;
}

/****************************************
 * AgentManageProperties.css END
 ****************************************/
