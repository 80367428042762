/****************************************
 * SideNav.css START
 ****************************************/


@media screen and (min-width: 768px) {
  .collapsed .side-panel {
    width: 40px;
  }

  .collapsed .side-panel .side-menu li > * {
    padding: 10px;
  }

  .collapsed .side-panel .logo,
  .collapsed .side-panel .side-menu span,
  .collapsed .side-panel .side-menu .menu-heading {
    display: none;
  }

  .collapsed .side-panel .side-menu svg {
    margin-right: 0;
  }

  .collapsed .side-panel .collapse-btn svg {
    transform: rotate(180deg);
  }

  .collapsed .page-content {
    left: 40px;
    width: calc(100% - 40px);
  }
}

/* SIDE PANEL STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.side-panel {
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  width: var(--sidenav-width);
  color: var(--mmg-txtColor);
  background: var(--mmg-background-colour);
}

.side-panel nav {
  display: flex;
  flex-direction: column;
  top: 0;
}

.side-panel .logo {
  display: block;
  margin: 0 15px;
}

.side-panel .logo svg {
  max-width: 120px;
  fill: var(--white);
}

.side-panel .toggle-mob-menu {
  display: none;
  margin-left: 5px;
  padding: 4px;
  background: var(--page-content-blockColor);
  border-radius: var(--border-radius);
}

.side-panel .toggle-mob-menu svg {
  fill: var(--black);
  transition: transform 0.2s;
}

.side-panel .side-menu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 35px;
}

/* .side-panel .side-menu li:nth-last-child(2) {
  margin-bottom: 35px;
} */

.side-panel .side-menu li:last-child {
  margin-top: auto;
  margin-bottom: 20px;
}

.side-panel .side-menu li > * {
  width: 100%;
  padding: 12px 15px;
}

.side-panel .side-menu .switcher {
  display: inline-block;
  width: auto;
}

.side-panel .side-menu .menu-heading h3 {
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 12px;
  margin-top: 12px;
  color: var(--headingColor);
}

.side-panel .side-menu svg {
  width: 20px;
  height: 20px;
  fill: var(--mmg-txtColor);
  margin-right: 10px;
}

.side-panel .side-menu a,
.side-panel .side-menu button {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.side-panel .side-menu a:hover,
.side-panel .side-menu a:focus,
.side-panel .side-menu button:hover,
.side-panel .side-menu button:focus {
  background: var(--mmg-background-colour-hover);
  color: var(--mmg-yellow);
  outline: none;
}

.side-panel .side-menu a:hover svg,
.side-panel .side-menu a:focus svg,
.side-panel .side-menu button:hover svg,
.side-panel .side-menu button:focus svg {
  fill: var(--mmg-yellow);
}

.side-panel.collapsed {
  width: 60px; /* Adjust this as needed */
}
.side-panel.collapsed .side-menu span {
  display: none; /* Hide text labels */
}

.side-panel.collapsed .side-menu svg {
  margin-right: 0;
}
.side-panel.collapsed .menu-heading h3 {
  display: none;
}





/* MQ RULES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (max-width: 767px) {
  /* @media screen and (max-device-width: 767px) { */
    .side-panel,
    .page-content {
      position: static;
      width: 100%;
    }
  
    .side-panel {
      padding: 10px;
      /* background-color: red; */
    }
  
    .side-panel nav {
      flex-direction: row;
    }
  
    .side-panel .logo {
      margin: 0;
    }
  
    .side-panel .logo svg {
      width: 83px;
      height: 35px;
    }
  
    .side-panel .toggle-mob-menu {
      display: block;
    }
  
    .side-panel .side-menu {
      position: absolute;
      left: 98px;
      top: 57px;
      margin-top: 0;
      z-index: 2;
      border-radius: var(--border-radius);
      background: var(--mmg-background-colour);
      visibility: hidden;
      opacity: 0;
      transform: scale(0.95);
      transition: all 0.2s;
    }
  
    .side-panel .side-menu li:nth-last-child(2) {
      margin-bottom: 12px;
    }
  
    .side-panel .side-menu li:last-child button,
    .search-and-user .user-profile .greeting {
      display: none;
    }  

    .mob-menu-opened .side-panel {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
    }
  
    .side-panel {
      visibility: hidden;
      opacity: 0;
      transform: translateX(-100%);
      transition: all 0.3s;
    }
  }



/****************************************
 * Notification Badge
 ****************************************/
 .notification-link {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px; /* Space between icon and text */
}

.notification-badge {
  position: absolute;
  /* top: 12px; */
  right: 65px; /* Adjust based on spacing */
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  /* padding: 2px 6px; */
  border-radius: 50%;
  min-width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}


/****************************************
 * SideNav.css END
 ****************************************/
  