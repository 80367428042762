/* [PropertyDetail.css START] */

/****************************************
 * General Container
 ****************************************/
 .property-detail-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
}

/****************************************
 * Panels
 ****************************************/
.detail-panel {
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff; /* Clean white background */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Subtle border */
}

/****************************************
 * Panel Headers
 ****************************************/
.detail-panel h2 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #000000; /* Black header text */
  margin-bottom: 1rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 0.5rem;
}

/****************************************
 * Form Styling
 ****************************************/
.detail-form {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal columns */
  gap: 1rem;
}

.detail-form input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.2); /* Subtle border */
  border-radius: 4px;
  background-color: #f9f9f9; /* Light background for inputs */
  color: #333333; /* Darker text color */
  font-size: 1rem;
}

.detail-form input[readonly] {
  background-color:rgba(17, 43, 26, 0.1);
  color: #333333; /* Darker text for better readability */
  cursor: not-allowed;
  border-color: rgba(0, 0, 0, 0.2); /* Subtle border */
}

/****************************************
 * Rows and Items
 ****************************************/
.detail-row {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

/* [PropertyDetail.css END] */
