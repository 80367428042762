/* Branding Header */
.brandingHeaderPanel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #112B1A; /* Dark green background */
  color: #D7CE96; /* Gold accent */
  padding: 20px 0;
  font-family: 'Libre Baskerville', serif;
  font-size: 2rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.logo {
  height: 50px;
  width: auto;
}

/* Form Container */
.formContainer {
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Fieldset Styling */
.fieldset {
  margin-top: 25px;;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.legend {
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  padding: 5px 10px;
  background-color: #112B1A; /* Same green as header */
  border-radius: 5px;
}

/* Form Rows */
.row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 15px;
}

.row > div {
  flex: 1;
}

.label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 5px;
  color: #555;
}

.input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.input:focus {
  border-color: #ffc107;
  outline: none;
  box-shadow: 0 0 5px rgba(255, 193, 7, 0.5);
}

/* Terms and Conditions */
.termsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
}

.termsContainer label {
  font-size: 0.9rem;
  color: #555;
}

/* Submit Button */
.submitContainer {
  text-align: center;
}

.submitButton {
  padding: 10px 20px;
  width: 50%;
  background-color: #112B1A;
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #08140c;
}
