/* LoginForm.module.css START */

@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap');

/****************************************
 * Login Page
 ****************************************/
.loginPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #112B1A; /* Dark green background */
  color: #fff;
  font-family: 'Libre Baskerville', serif;
}

/****************************************
 * Branding Row
 ****************************************/
.brandingRow {
  margin-bottom: 20px;
  text-align: center;
}

.brandingTitle {
  font-size: 2.5rem;
  font-weight: 700;
  color: #D7CE96; /* Gold accent color */
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/****************************************
 * Login Form
 ****************************************/
.loginForm {
  background-color: #f9f9f9;
  color: #333;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 500px;
  max-width: 90%;
}

.loginForm h2 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
}

.loginForm label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1rem;
}

.loginForm input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
}

.loginForm input:focus {
  outline: 2px solid #D7CE96;
  border-color: #D7CE96;
  box-shadow: 0 0 5px rgba(215, 206, 150, 0.5);
}

/****************************************
 * Error Message
 ****************************************/
.error {
  color: red;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

/****************************************
 * Submit Button
 ****************************************/
.submitButton {
  width: 100%;
  padding: 10px 0;
  background-color: #4CAF50; /* Green button */
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submitButton:hover {
  background-color: #45a049;
}

.brandingLogo {
  width: 150px; /* Adjust width */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 10px;
}

/* LoginForm.module.css END */
