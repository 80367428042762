/****************************************
 * Header.css START
 ****************************************/
 @import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap');

 /* Header container */
 .header-panel {
   width: 100%;
   height: var(--header-height);
   background-color: #112B1A; /* Dark green for the header background */
   padding: 10px 20px;
   position: fixed;
   top: 0;
   left: 0;
   /* z-index: 1000; */
   display: flex;
   justify-content: space-between;
   align-items: center;
   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
 }
 
 /* Left section with the logo */
 .header-left img.logo {
   width: 120px;
 }
 
 /* Center section with the title */
 .header-center {
  flex: 2;
  display: flex;
  align-items: flex-end; /* Align the text to the bottom of the div */
  justify-content: flex-start; /* Align the text to the left of the div */
  margin-left: 30px;
}

.header-title-text {
  font-family: 'Libre Baskerville', serif;
  font-size: 2.3rem; /* Increased for better visibility */
  font-weight: 400; /* Medium weight for elegance */
  color: #D7CE96; /* Match the text color with the logo */
  margin: 0;
  position: relative; /* Allows fine-tuning */
  left: 0; /* Ensure it is left-aligned */
}
 
.header-slogan {
  font-family: 'Libre Baskerville', serif;
  font-size: 1.1rem; /* Slightly smaller but still noticeable */
  font-weight: 300; /* Lighter weight for subtlety */
  color: #D7CE96; /* Matching the header color scheme */
  margin-top: 2px; /* Create space from the title */
  text-align: left; /* Align with the title */
  display: block; /* Ensure it stacks under the title */
}

 /* Right section with user info and logout button */
 .header-right {
   flex: 1;
   display: flex;
   justify-content: flex-end;
   align-items: center;
 }
 
 .user-info {
   display: flex;
   flex-direction: column;
   align-items: flex-end;
 }
 
 .user-email {
   font-size: 0.9rem;
   color: #fff;
 }
 
 .user-role {
   font-size: 0.8rem;
   color: #aaa;
   margin-bottom: 5px;
 }
 
 /* Logout button */
 .logout-button {
   background-color: #2C3E50; /* Subtle dark blue-gray */
   color: white;
   padding: 8px 16px;
   font-size: 0.9rem;
   border: none;
   border-radius: 4px;
   cursor: pointer;
   transition: background-color 0.3s ease;
 }
 
 .logout-button:hover {
   background-color: #1A252F; /* Darker shade on hover */
 }
 
 .not-signed-in {
   font-size: 0.9rem;
   color: #fff;
 }

/* Feedback message styling */
.feedback-message {
  color: greenyellow; 
  background-color:mediumblue;
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
  padding: 0 25px;
  display: block;
  opacity: 1;
  transition: opacity 1s ease-out; /* Smooth fade-out transition */
}

/* Apply fade-out class after 5 seconds */
.feedback-message.fade-out {
  opacity: 0;
}

 /****************************************
  * Header.css END
  ****************************************/
 