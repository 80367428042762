/****************************************
 * FormsDefault.css START
 ****************************************/

 
 /****************************************
 * FORM CONTAINER
 ****************************************/
 .form-container {
  margin: 0 auto;
  padding: 2em;
  max-width: 400px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-container h2 {
  text-align: center;
  color: #333;
  font-weight: bold;
  margin-bottom: 1.2em;
}

.form-container label {
  display: block;
  margin-bottom: 0.5em;
  font-weight: bold;
  color: #555;
}

/****************************************
 * INPUT AND SELECT STYLES
 ****************************************/
.form-container input,
.form-container select {
  display: block;
  width: 100%;
  padding: 0.7em;
  margin-bottom: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

.form-container select {
  background-color: #fff;
}

/****************************************
 * BUTTON STYLES
 ****************************************/
.form-container button {
  display: block;
  width: 100%;
  padding: 0.7em;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.form-container button:hover {
  background-color: #45a049;
}

/****************************************
 * MESSAGE STYLES
 ****************************************/
.error-message {
  color: red;
  font-size: 0.9em;
  margin-bottom: 1em;
}

.success-message {
  color: green;
  font-size: 0.9em;
  margin-bottom: 1em;
}

.submission-feedback {
  font-weight: bold;
}

.submitting {
  color: black; /* While submitting */
}

.completed {
  color: green; /* After completion */
}



/****************************************
 * IMAGE UPLOAD
 ****************************************/

.image-upload {
  margin: 10px 0;
}

.file-input {
  display: block;
  margin: 5px 0;
}

.upload-status {
  font-size: 0.9em;
  color: #555;
}

.thumbnail-display {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.thumbnail-container {
  position: relative;
}

.thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
}

.delete-button {
  position: absolute;
  top: 2px;
  right: 2px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  cursor: pointer;
}

.input-group-row {
  display: flex;
  gap: 10px;
}

.input-group {
  display: flex;
  flex-direction: column;
}

/* Set Address to take remaining space */
.input-group:not(.postcode-group) {
  flex: 1;
}

/* Fix width of Postcode field */
.postcode-group {
  flex-basis: 400px;
}

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--mmg-txtColor-contrast);
}

/****************************************
 * FormsDefault.css END
 ****************************************/

