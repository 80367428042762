/* [SettingsTrades.css START] */

/****************************************
 * Container
 ****************************************/
 .settings-trades-container {
  margin: 5px 0;
  padding: 10px;
  background-color: var(--form-bg); /* For dark/light mode support */
  border-radius: 10px;
  border: 1px solid #ccc;
}

/****************************************
 * Form Elements
 ****************************************/
.trade-form {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.trade-form input[type="text"] {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.trade-form label {
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 5px;
}

.trade-form input[type="checkbox"] {
  width: auto;
  margin: 0;
}

.trade-form button {
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
}

.trade-form button:hover {
  background-color: #0056b3;
}

/****************************************
 * Trade List
 ****************************************/
.trade-list {
  list-style-type: none;
  padding: 0;
  margin-top: 15px;
}

.trade-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
}

.trade-list-item input[type="text"] {
  flex: 0.5; /* Sets input width to half of available space */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 10px;
}

.trade-list-item label {
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 5px;
}

/****************************************
 * Buttons
 ****************************************/
.trade-list-item button {
  background-color: rgb(7, 186, 245);
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
}

.trade-list-item button:hover {
  background-color: rgb(0, 132, 200);
}

/****************************************
 * FLASH MESSAGE
 ****************************************/
.trade-flash-message {
  margin-top: 4px;
  font-weight: bold;
  color: green;
}


/* [SettingsTrades.css END] */
