/* [SettingsPreQuotedWorks.css START] */

/****************************************
 * Pre Quoted Works Form Styles
 ****************************************/

 .pqw-form {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: flex-start;
  margin: 20px 0 15px 0;
  width: 100%;
}

.pqw-form .pqw-input-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pqw-form .pqw-textarea-group {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pqw-form .pqw-button-group {
  display: flex;
  justify-content: flex-end; /* Align button to the bottom */
  height: 100%; /* Match height with other form groups */
}

/****************************************
* Input and Button Styles
****************************************/

.pqw-form label {
  font-weight: bold;
  color: #333;
}

.pqw-form input[type="text"],
.pqw-form select,
.pqw-form textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1em;
}

.pqw-form textarea {
  resize: vertical;
  height: 100px;
}

.pqw-form button {
  align-self: flex-end;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.pqw-form button:hover {
  background-color: #0056b3;
}

/****************************************
* Flash Message Line and Scrollable List
****************************************/

.feedback-line {
  position: sticky;
  top: 45px;
  background-color: #ffffff;
  padding: 5px 10px;
  font-weight: bold;
  color: green;
  border-bottom: 1px solid #ccc;
  z-index: 1;
}

.pqw-list {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  list-style-type: none;
  margin: 0;
}

.pqw-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: #f9f9f9;
}

.pqw-list li span {
  flex: 1;
}

.pqw-list button {
  margin-left: 5px;
}

.pqw-form input[type="number"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1em;
}


/* [SettingsPreQuotedWorks.css END] */
