/****************************************
 * AgentDashboards.css START
 ****************************************/



/****************************************
 * Dashboard General Layout Styles
 ****************************************/
 /* .agent-dashboard, .landlord-dashboard {
  padding: 20px;
  margin-top: var(--header-height);
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: var(--mmg-txtColor-contrast);
} */

/****************************************
 * Dashboard Panel Styles
 ****************************************/
.dashboard-panel {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.dashboard-panel h2 {
  margin-top: 0;
}

/****************************************
 * Invite Landlord Form Styles
 ****************************************/
.invite-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
}

.invite-form .input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.invite-form label {
  margin-bottom: 5px;
  font-weight: bold;
}

.invite-form input,
.invite-form select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

/****************************************
 * DateSelect Styles
 ****************************************/
.date-selects {
  display: flex;
  gap: 10px;
  align-items: center;
}

.date-selects select {
  width: calc(33.33% - 10px); /* Ensure equal width for each select */
}

.date-selects select:last-child {
  margin-right: 0; /* Ensure the last select has no right margin */
}

/****************************************
 * Invite Form Action Buttons (Flex Alignment)
 ****************************************/
.invite-form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.close-button,
.invite-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: auto;
}

.close-button {
  background-color: #f8f9fa;
  color: #343a40;
  border: 1px solid #ccc;
}

.close-button:hover {
  background-color: #e2e6ea;
}

.invite-button {
  background-color: #28a745;
}

.invite-button:hover {
  background-color: #218838;
}

/****************************************
 * Dashboard Button Styles
 ****************************************/
.dashboard-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.dashboard-button:hover {
  background-color: #0056b3;
}

/****************************************
 * Dashboard Header Styles
 ****************************************/
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: var(--mmg-txtColor-contrast);
}

.mode-toggle {
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  background-color: transparent;
  cursor: pointer;
  transition: color 0.3s ease;
}

.dark-mode-button {
  color: white;
}

.light-mode-button {
  color: green;
}

/* Add spacing for fallback buttons */
.fallback-buttons {
  display: flex;
  justify-content: space-around; /* Spread buttons evenly */
  margin-top: 20px; /* Add space above the button row */
}

.fallback-buttons .dashboard-button {
  padding: 10px 20px; /* Make buttons more clickable */
  font-size: 1rem; /* Ensure font size is consistent */
}


.agent-dashboard-welcome-panel {
  /* background-color: red; */
  margin-top: 40px;
  display: block;
  width: 100%;
  font-size: large;
}

.agent-dashboard-welcome-panel p {
  border: 1px solid rgba(0, 0, 0, 0.1); /* Subtle gray border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px; /* Add some padding for visual separation */
  margin-bottom: 20px;
}

.panel-header.dark,  .panel-header.light {
  margin-bottom: 5px;
}

/* .panel-header.dark {
 background-color: pink; 
}

.panel-header.light {
  background-color: purple; 
 } */
 

/****************************************
 * Theme toggle Styles
 ****************************************/


 .mode-toggle.light {
  background-color: transparent;
  color: var(--mmg-button-green-colour); 
  border: none;
  font-weight: bold;
  cursor: pointer;
}

.mode-toggle.light:hover {
  color: var(--mmg-button-green-hover-colour);
  text-decoration: underline; 
}

.mode-toggle.dark {
  background-color: transparent;
  color: var(--mmg-txtColor);
  border: none;
  font-weight: bold;
  cursor: pointer;
}

.mode-toggle.dark:hover {
  color: var(--mmg-background-colour-hover);
  text-decoration: underline; 
}


/****************************************
 * AgentDashboards.css END
 ****************************************/
