/****************************************
 * JobDetailForm.css START
 ****************************************/

 .job-detail-form-container {
  margin: 5px 0;
  padding: 10px;
  background-color: var(--form-bg); /* Using color variable for d/l mode */
  /* background-color: red; */
  border-radius: 10px;
}

.job-detail-form-container label {
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
  /* margin-top: 20px; */
}

.job-detail-form-container input,
.job-detail-form-container select,
.job-detail-form-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.job-detail-button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.job-detail-return-button {
  background-color: rgb(7, 186, 245);
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.job-detail-accept-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.job-detail-return-button:hover {
  background-color: rgb(0, 132, 200);
}

.job-detail-accept-button:hover {
  background-color: #45a049;
}

/* New rule for renamed class */
.job-detail-form-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

/* Each column in the row has an equal width */
.job-detail-form-row .form-group,
.job-detail-form-row .job-detail-form-group {
  flex: 1;
  min-width: 20%;
}

/* Ensure labels and inputs align consistently */
.job-detail-form-group label {
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
}

.job-detail-form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 15px;
}


/****************************************
 * JobDetailForm.js END
 ****************************************/

 /****************************************
 * JobTabs.js START
 ****************************************/

 .job-detail-job-tabs {
  border-top: 1px solid #ccc;
  margin-top: 20px;
}

.tab-buttons {
  display: flex;
  border-bottom: 1px solid #ccc;
  background-color: var(--tab-bg-color); /* Use color variable for dark/light mode */
}

.tab-buttons button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  transition: background-color 0.3s;
  color: var(--mmg-txtColor-contrast);
}

.tab-buttons button:hover {
  background-color: #e0e0e0;
}

.tab-buttons .active {
  background-color: var(--tab-active-bg); /* Define active tab color variable */
  font-weight: bold;
}

.tab-content {
  background-color: var(--content-bg);
  border: 1px solid #ccc;
  border-top: none;
}

/****************************************
 * JobTabs.css END
 ****************************************/

 /****************************************
 * JobTabsDetail.css START
 ****************************************/

 .job-tabs-detail {
  padding: 20px;
  background-color: var(--content-bg); /* For dark/light mode support */
}

.panel-content {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.item {
  flex: 1 1 200px; /* Flexible layout for responsive behavior */
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.bold-form {
  font-weight: bold;
}

.info-grey-small {
  font-size: 0.875em;
  color: gray;
}

.detail-address {
  margin-top: 5px;
}

/****************************************
 * JobTabsDetail.css END
 ****************************************/

/****************************************
 * JobTopPanel.css START
 ****************************************/

 .job-top-panel {
  color: var(--mmg-txtColor-contrast);
  background-color: var(--mmg-grid-title-background-colour);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  height: var(--job-top-panel-height);
}

.job-info {
  font-size: 16px;
  margin-top: 10px;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.decline-button, .accept-button {
  padding: 8px 12px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.decline-button {
  background-color: rgb(7, 186, 245);
  color: white;
}

.accept-button {
  background-color: #4CAF50;
  color: white;
}

.decline-button:hover {
  background-color: rgb(0, 132, 200);
}

.accept-button:hover {
  background-color: #3e8e41;
}

/****************************************
 * JobTopPanel.css END
 ****************************************/

