/* SettingsAddUser.module.css START */

.addUserBody {
  background-color: transparent;
  padding: 20px;
  font-family: Arial, sans-serif;
  height: calc(98vh - var(--header-height));
}

.addUserFormContainer {
  margin: 0 auto;
  max-width: 500px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.addUserLabel {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.addUserSelect, .addUserInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.addUserCheckboxGroup {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.addUserCheckboxGroup input {
  margin-right: 10px;
}

.addUserErrorMessage, .addUserSuccessMessage {
  color: red;
  font-size: 0.9rem;
  margin-bottom: 15px;
}

.addUserSuccessMessage {
  color: green;
}

.addUserButton {
  background-color: #4CAF50;
  color: #fff;
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 90%;
}

.addUserButton:hover {
  background-color: #45a049;
}

.addUserButtonGroup {
  display: flex;
  justify-content:center;
  gap: 10px; /* Adds space between buttons if needed */
}

/* SettingsAddUser.module.css END */
