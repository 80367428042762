/****************************************
 * PopForm Overlay and Backdrop
 ****************************************/

 .popform-backdrop,
 .pop-form-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.5); /* semi-transparent background */
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 9999; /* ensures it overlays other content */
 }
 
 /****************************************
  * PopForm Content
  ****************************************/
 
 .popform-content,
 .pop-form {
  margin-top: 50px;
   background-color: #fff;
   border-radius: 8px;
   width: 90vw; /* 80% of viewport width */
   height: 80vh; /* 80% of viewport height */
   padding: 20px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
   overflow-y: auto; /* Add scroll for overflow */
 }
 
 /****************************************
  * PopForm Header
  ****************************************/
 
 .popform-header,
 .pop-form-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid #ddd;
   padding: 10px 20px 10px;
   margin-bottom: 20px;
   /* background-color: #f8f9fa; */
   text-align: center;
 }
 
 .pop-form-header h2 {
   margin: 0;
   font-size: 1.5em;
 }
 
 .close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0 5px;
  color: #333; /* Adjust color as needed */
 }
 
 .close-btn:hover {
  color: #333; /* Keeps the color the same on hover */
  transform: none;
  background: none;
}

.approval-buttons {
  display: flex;
  justify-content: space-between;
  /* margin-top: 15px; */
}

.approve-btn, .reject-btn {
  padding: 8px 16px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 10px;
  transition: background-color 0.2s ease;
}

.approve-btn {
  background-color: #4CAF50; /* Adjust to match your primary color */
  color: white;
}

.reject-btn {
  background-color: #f44336; /* Adjust to match your secondary color */
  color: white;
}

.approve-btn:hover {
  background-color: #45a049; /* Slightly darker shade */
}

.reject-btn:hover {
  background-color: #d32f2f; /* Slightly darker shade */
}
