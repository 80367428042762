/****************************************
 * Settings.css START
 ****************************************/


.page-heading {
  color: lightsalmon;
}

.settings-page {
  display: flex;
  color: var(--mmg-txtColor);
  height: calc(98vh - var(--header-height))
  /* color: lightsalmon; */
}

/****************************************
 * SECONDARY NAV STYLES
 ****************************************/

.second-column-nav {
  width: 120px;
  margin-left: 0;
  margin-top: 25px;
}

.second-column-nav ul {
  list-style-type: none;
  padding: 0;
}

.second-column-nav li {
  padding: 10px;
  cursor: pointer;
}

.second-column-nav li:hover {
  background-color: #f0f0f0;
}

.content-section {
  flex-grow: 1;
  padding: 0 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th, table td {
  border: 1px solid #ddd;
  padding: 8px;
}

table th {
  background-color: #04163b;
  text-align: left;
}

/* Change cursor for table rows */
table tbody tr {
  cursor: pointer; /* Change to hand pointer on hover */
}

/* Optionally, you can apply this to the side navigation links as well */
.second-column-nav ul li {
  cursor: pointer; /* Change to hand pointer for navigation links */
}


/****************************************
 * Settings.css END
 ****************************************/
