/****************************************
 * SettingsUsers.css START
 ****************************************/

 .users-table h2 {
  color: var(--mmg-txtColor);
}


.users-heading {
  margin: 20px 0;
}

/****************************************
 * SettingsUsers.css END
 ****************************************/
